import React from 'react';
import './styles.css';

export default function Footer() {
    return(
        <div className={'footer_content'}>
            <h3 className={'footer_text'}>
                Todos os direitos reservados a DicasMEI e Wiser
            </h3>
        </div>
    );
}