import React from 'react';
import './styles.css';

export default function Formalize() {
    return (
        <div className={'formalize_content'}>
            <h3 className={'formalize_title'}>
                Agente Wiser, venha fazer parte deste time campeão.
            </h3>
            <p className={'formalize_description'}>
                Com a DicasMEI você terá condições de emitir notas fiscais de serviço de maneira fácil e rápida,
                todo mês durante 1 ano, além do apoio com os serviços apontados abaixo. Lembrando que a abertura da sua
                MEI é gratuita e pode ser feita tanto com a DicasMEI quanto no portal do empreendedor.
                <br />
                <br />
                Todos os nossos serviços estarão disponíveis através da sua assinatura anual, por apenas 12 parcelas de
                R$17,40 ou o valor integral no boleto de R$208,80
            </p>
            <div className={'formalize_button_sizer'}>
                <button
                    className={'formalize_button'}
                    onClick={() => window.location.href= 'https://formalizacao.wiser.dicasmei.com.br/'}
                >
                    Quero ser associado da DicasMEI
                </button>
                <button
                    className={'formalize_alt_button'}
                    onClick={() => window.location.href= 'https://formalizacao.wiser.dicasmei.com.br/#/existinglogin'}
                >
                    Sou assinante da DicasMEI
                </button>
            </div>
        </div>
    );
}