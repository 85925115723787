import React from 'react';
import './styles.css';

export default function Partners() {

    const partners = [
        'avon',
        'natura',
        'bradesco',
        'wiser'
    ];

    return(
        <div className={'partners_content'}>
            <h4 className={'partners_title'}>
                Parceiros de Negócios DicasMEI
            </h4>
            <p className={'partners_description'}>
                Confira os principais parceiros da DicasMEI
            </p>
            <div className={'partners_list'}>
                {partners.map((partner, key) => (
                    <div key={key} className={'partner'}>
                        <img className={'partner_image'} src={`/assets/dicasmei_partners/${partner}.png`} />
                    </div>
                ))}
            </div>
        </div>
    );
}