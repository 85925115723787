import React from 'react';
import icon_maos from '../../assets/icon_maos.svg';
import emissao_icon from '../../assets/icon_nota.svg';
import icon_mei from '../../assets/icon_mei.svg';
import './styles.css';

export default function Services({
    setVideos,
    setVideoUrl,
    setModalTitle,
    setModalDescription
}) {
    
    const services = [
        {
            title: 'Parceria DicasMEI e Wiser',
            description: 'Confira o vídeo com detalhes dessa parceria',
            url: '',
            icon: icon_maos,
            videoUrl: 'https://www.youtube.com/watch?v=vm978GX0XRk'
        },
        {
            title: 'Dúvidas sobre o MEI',
            description: 'Tire suas principais dúvidas com os vídeos da equipe DicasMEI',
            url: '',
            icon: icon_mei,
            videoArray: [
                'https://www.youtube.com/watch?v=fVVGaV-VOSo',
                'https://www.youtube.com/watch?v=SFajdfYc0EI',
                'https://www.youtube.com/watch?v=YbM_YiECB-U',
                'https://www.youtube.com/watch?v=ySH45TqeZ50',
                'https://www.youtube.com/watch?v=_ETo3WUyB3A'
            ]
        },
        {
            title: 'Emissão de nota Fiscal',
            description: 'Para quem já é MEI, veja por vídeo como emitir notas.',
            url: '',
            icon: emissao_icon,
            videoUrl: 'https://youtu.be/FiZNt-MtJ6Q',
            fullDescription: 'Para os assinantes DicasMEI, ' +
                'a emissão de nota Wiser acontecerá a partir de 22/07 de forma automatizada todo o mês.  ' +
                'Antes disso, o Agente Wiser tem algumas tarefas.\n' +
                ' Confira no vídeo abaixo. '
        }
    ];
    
    const callVideoModal = (service) => {
        setModalTitle(service.title);
        setVideoUrl(service.videoUrl);
        if (service.videoArray) {
            setVideos(service.videoArray);
        } else {
            setVideoUrl(service.videoUrl);
        }
        setModalDescription(service.fullDescription);
    };

    return(
        <div className={'services_content'}>
            <h3 className={'services_title'}>
                Principais informações para o dia-a-dia do agente Wiser
            </h3>
            <p className={'service_description'}>
                A DicasMEI disponibiliza conteúdos em vídeo para ajudar na Abertura de empresa MEI e Emissão de nota fiscal
            </p>
            <div className={'services_card'}>
                {
                    services.map((service, key) => (
                        <div className={key === 0 ? 'service_card width_mobile' : 'service_card'} key={key}>
                            <div className={'service_card_text_container'}>
                                <img className={'service_card_icon'} src={service.icon} />
                                <div className={'service_card_texts'}>
                                    <h4 className={'service_card_title'}>
                                        {service.title}
                                    </h4>
                                    <p className={'service_card_description'}>
                                        {service.description}
                                    </p>
                                </div>
                            </div>
                            {((service.videoUrl || service.videoArray) && (
                                <div 
                                    className={'service_card_button_container'}
                                    onClick={() => callVideoModal(service)}
                                >
                                        Quero conferir
                                </div>
                            ))}
                        </div>
                    ))
                }
            </div>
            {/*<button className={'btn_inverse services_button'}>*/}
            {/*    Ver todos os Serviços*/}
            {/*</button>*/}
        </div>
    );
}