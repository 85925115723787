import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import ReactGA from 'react-ga';
import ReactPlayer from 'react-player/youtube';
import {GrClose} from 'react-icons/gr';
import './App.css';
import Header from './components/Header';
import Formalize from './components/Formalize';
import Services from './components/Services';
import Benefits from './components/Benefits';
import Partners from './components/Partners';
import Footer from './components/Footer';

function App() {

    const customStyles = {
        content : {
            height                : 'auto',
            width                 : '40em',
            top                   : '50%',
            left                  : '50%',
            right                 : 'auto',
            bottom                : 'auto',
            marginRight           : '-50%',
            transform             : 'translate(-50%, -50%)',
            borderRadius          : '8px',
            boxShadow             : '0 19px 38px 0 rgba(0, 0, 0, 0.26)',
            textAlign             : 'center'
        }
    };

    const [showModal, setShowModal] = useState(false);

    const [videoUrl, setVideoUrl] = useState('');

    const [videos, setVideos] = useState([]);

    const [modalTitle, setModalTitle] = useState('');

    const [modalDescription, setModalDescription] = useState('');

    useEffect(() => {
        ReactGA.initialize('UA-112487562-11');
        ReactGA.pageview(window.location.pathname + window.location.hash);
    }, []);

    useEffect(() => {
        if (modalTitle !== '' && modalDescription !== '' && (videoUrl !== '' || videos.length >= 1)) {
            setShowModal(true);
        }
    }, [modalTitle, modalDescription, videoUrl]);

    const closeModal = () => {
        setShowModal(false);
        setModalTitle('');
        setModalDescription('');
        setVideoUrl('');
    };

    return (
        <div className="App">
            <Header />
            <Formalize />
            <Services
                setVideos={setVideos}
                setVideoUrl={setVideoUrl}
                setModalTitle={setModalTitle}
                setModalDescription={setModalDescription}
            />
            <Benefits />
            <Partners />
            <Footer />
            <Modal
                isOpen={showModal}
                style={customStyles}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
            >
                <GrClose
                    className={'modal_close_icon'}
                    onClick={closeModal}
                />
                <h3 className={'modal_title'}>
                    {modalTitle}
                </h3>
                {(modalDescription !== '') && (
                    <p className={'modal_description'}>
                        {modalDescription}
                    </p>
                )}
                {(videoUrl) ? (
                    <ReactPlayer
                        className={'modal_video'}
                        url={videoUrl}
                        controls={true}
                    />
                ) : (
                    <div className={'videos_carousel_container'}>
                        <div className={'videos_carousel'}>
                            {
                                videos.map((video, key) => (
                                    <ReactPlayer
                                        key={key}
                                        className={'modal_video'}
                                        url={video}
                                        controls={true}
                                    />
                                ))
                            }
                        </div>
                    </div>
                )}
                <button
                    className={'modal_button'}
                    onClick={() => window.location.href= 'https://formalizacao.wiser.dicasmei.com.br/'}
                >
                    Quero ser assinante
                </button>
            </Modal>
        </div>
    );
}

export default App;
