import React from 'react';
import './styles.css';

export default function Benefits() {

    const partners = [
        {fileName: 'inove', name: 'Supletivo ensino médio curso técnico e qualificação profissional', partner: true},
        {fileName: 'salu', name: 'Telemedicina', partner: true},
        {fileName: 'descomplica', name: 'Cursinho preparatório para o ENEM', partner: true},
        {fileName: 'hapvida', name: 'Plano odontológico', partner: true},
    ];

    return(
        <div className={'benefits_content'}>
            <div className={'benefits_header_content'}>
                <div className={'benefits_header_texts'}>
                    <h4 className={'benefits_header_title'}>
                        Em breve a DicasMEI vai oferecer soluções de empresas parceiras
                    </h4>
                    <p className={'benefits_header_description'}>
                        Você terá acesso a descontos em soluções para sua a vida profissional, pessoal e familiar.
                    </p>
                </div>
            </div>
            <div className={'benefits_partners_list'}>
                {
                    partners.map((partner, key) => {
                        return (
                            <div
                                key={key}
                                className={'benefits_partner'}
                            >
                                <img
                                    className={'benefits_partner_image'}
                                    src={`/assets/partners/${partner.fileName}.png`}
                                />
                                <p className={'benefits_partner_name'}>
                                    {partner.name}
                                </p>
                            </div>);
                    })
                }
            </div>
        </div>
    );
}