import React from 'react';
import Logo from '../../assets/logo.svg';
import './styles.css';

export default function Header() {
    return(
        <div
            className={'header_container'}
        >
            <img className={'header_logo'} src={Logo} />
            <span className={'header_line'} />
            <span className={'header_title'}>
                DicasMEI e Wiser são parceiras e quem ganha é você!
            </span>
        </div>
    );
}